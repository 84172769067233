import React from "react"
import { Link } from "gatsby"

// Stylesheet
import styles from "./index.module.scss"

// Images
import BG from "../../images/404-bg.jpg"

const NotFoundContainer = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url(${BG})` }}
      >
        <div className="max-w-6xl mx-auto px-4 xl:px-0 py-12 md:pt-20">
          <h1>
            404 Error
            <br />
            We couldn't find
            <br />
            the page you're looking for
          </h1>
          <p>
            The link you clicked may be broken or the page may have been
            removed.
          </p>
          <Link to="/">
            <button className="btn twine-gradient">Return to Homepage</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundContainer
