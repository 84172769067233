import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import NotFoudContainer from "../containers/404"

const NotFoundPage = () => (
  <Layout stroke={false}>
    <SEO title="404: Page Not found | Tribute2Moms" />
    <NotFoudContainer />
  </Layout>
)

export default NotFoundPage
